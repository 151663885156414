import { createStore } from 'vuex';

// http
import { addDeviceInfo } from '../http/user';

export default createStore({
    state: {
        accountData: null,
        scannedOntId: null,
        scannedRouterIds: [],
        userName: null,
        operator: null,
    },
    mutations: {
        setAccountData(state, accountData) {
            state.accountData = accountData;
        },
        setUserName(state, userName) {
            state.userName = userName;
        },
        setOperator(state, operator) {
            state.operator = operator;
        },
        setAccountPropertiesData(state, properties) {
            const previousProperties = state.accountData.properties;
            const newProperties = { ...previousProperties, ...properties };
            state.accountData.properties = newProperties;
        },
        setScannedOntId(state, id) {
            state.scannedOntId = id;
        },
        setScannedRouterId(state, ids) {
            state.scannedRouterIds = ids;
        },
    },
    actions: {
        setAccountData({ commit }, accountData) {
            commit('setAccountData', accountData.account_info);
            commit('setUserName', accountData.account_info?.user_owner_info ? `${accountData.account_info?.user_owner_info?.first_name} ${accountData.account_info?.user_owner_info?.last_name}` : '-');
            commit('setOperator', accountData.account_info?.properties ? accountData.account_info?.properties?.provider_id : null);
        },
        setScannedOntId({ commit }, id) {
            commit('setScannedOntId', id);
        },
        setScannedRouterId({ commit, state }, routerId) {
            const newRouterIds = [
                ...state.scannedRouterIds,
                routerId,
            ];
            commit('setScannedRouterId', newRouterIds);
        },
        removeScannedRouterId({ commit, state }, routerId) {
            commit('setScannedRouterId', state.scannedRouterIds.filter(id => routerId !== id));
        },
        resetOntAndRouterId({ commit }) {
            commit('setScannedOntId', null);
            commit('setScannedRouterId', []);
        },
        async updateResourceMap({ commit, state }, resources) {
            const resourcesForPayload = {};
            let deviceInfo = [
                {
                    device_type: 'ont',
                    serial_number: resources.ontId,
                },
            ];
            if (resources.ontId) {
                resourcesForPayload.ont_id = resources.ontId;
            }
            if (resources.routerIds?.length) {
                resourcesForPayload.router_ids = resources.routerIds;
                deviceInfo = [
                    ...deviceInfo,
                    ...resources.routerIds.map(routerId => ({
                        device_type: 'router',
                        serial_number: routerId,
                    })),
                ];
            }
            if (resources.taskId) {
                resourcesForPayload.external_task_id = resources.taskId;
            }
            if (resources.serviceId) {
                resourcesForPayload.external_service_id = resources.serviceId;
            }

            const isPayload = !!Object.keys(resourcesForPayload).length;
            if (isPayload) {
                await addDeviceInfo(deviceInfo, state.accountData.account_id);
            }
            commit('setAccountPropertiesData', resourcesForPayload);
        },
    },
    getters: {
        getScannedOntId: state => state.scannedOntId,
        getScannedRouterIds: state => state.scannedRouterIds,
        getUserName: state => state.userName,
        getOperator: state => state.operator,
        getChrIntegrationAccountId(state) {
            return state?.accountData?.properties?.chr_integration_account_id;
        },
        getChrAccountId(state) {
            return state?.accountData?.properties?.chr_account_id;
        },
        getServiceId(state) {
            return state?.accountData?.properties?.external_service_id;
        },
        getOntId(state) {
            return state?.accountData?.properties?.ont_id;
        },
        getRouterIds(state) {
            return state?.accountData?.properties?.router_ids || ['-'];
        },
        getExternalTaskId(state) {
            return state?.accountData?.properties?.external_task_id;
        },
        getAccountData(state) {
            return state?.accountData || {};
        },
    },
});
