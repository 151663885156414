<template>
    <div class="page-content login-page-wrapper">
        <h1>Fiber Installer App</h1>
        <div class="login-button-wrapper">
            <AppButton
                label="Log In"
                :colorScheme="Colors.blue"
                :iconClass="Icons.search"
                @click="signIn"
            />
        </div>
    </div>
</template>

<script>
    // libs and helpers
    import * as msal from '@azure/msal-browser';
    import { lfAuth } from '../http/user';
    import { setSessionData, setAutorizationHeader } from '../common/sessionHelper';
    import { UserGroup, setUserGroup } from '../utils/userGroup';

    // components
    import AppButton, { Colors, Icons } from '../components/AppButton.vue';

    export default {
        name: 'Login',
        components: {
            AppButton,
        },
        data() {
            return {
                email: '',
                password: '',
                Colors,
                Icons,
                msal: null,
            };
        },
        async created() {
            const msalConfig = {
                auth: {
                    clientId: process.env.VUE_APP_AZURE_AD_CLIENT_ID,
                    authority: `https://login.microsoftonline.com/${process.env.VUE_APP_AZURE_AD_TENANT_ID}`,
                    redirectUri: `${window.location.origin}`,
                },
            };

            this.msal = new msal.PublicClientApplication(msalConfig);
            await this.msal.initialize();
        },
        methods: {
            async signIn() {
                const azureAccessToken = await this.signIntoAzure();
                if (!azureAccessToken) {
                    return;
                }
                const userGroup = this.mapUserGroup(azureAccessToken);
                if (!userGroup.length) {
                    this.$eventBus.emit('showAlert', {
                        message: 'Authorization error. Please contact the T-Mobile Fiber team.',
                    });
                    return;
                }
                this.signIntoLF(azureAccessToken);
                this.setUserGroup(userGroup);
                this.$router.push({ name: 'search' });
            },
            async signIntoAzure() {
                try {
                    this.$Progress.start();
                    const loginRequest = {
                        scopes: ['profile'],
                    };
                    const login = await this.msal.loginPopup(loginRequest);
                    const idToken = login?.idToken;
                    this.$Progress.finish();
                    return idToken;
                } catch (error) {
                    this.$Progress.fail();
                    this.$eventBus.emit('showAlert', {
                        message: 'Authorization error. Please contact the T-Mobile Fiber team.',
                    });
                    return null;
                }
            },
            async signIntoLF(azureAccessToken) {
                try {
                    this.$Progress.start();
                    const response = await lfAuth(azureAccessToken);
                    setSessionData(response.data);
                    setAutorizationHeader();
                    this.$Progress.finish();
                } catch (error) {
                    this.$Progress.fail();
                    this.$eventBus.emit('showAlert', {
                        message: 'Authorization error. Please contact LotusFlare.',
                    });
                }
            },
            mapUserGroup(idToken) {
                const groups = this.msal.getAccount({ idToken })
                    ?.idTokenClaims?.groups || [];
                const userGroups = [];
                for (const group of groups) {
                    if (group === process.env.VUE_APP_AZURE_AD_GROUP_ADMIN) {
                        userGroups.push(UserGroup.admin);
                    }
                    if (group === process.env.VUE_APP_AZURE_AD_GROUP_INSTALLER) {
                        userGroups.push(UserGroup.installer);
                    }
                    if (group === process.env.VUE_APP_AZURE_AD_GROUP_SUPERVISOR) {
                        userGroups.push(UserGroup.supervisor);
                    }
                }
                return userGroups;
            },
            setUserGroup(userGroups) {
                setUserGroup(userGroups);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .login-page-wrapper {
        padding-top: 40px;
        text-align: center;

        h1 {
            margin-bottom: 24px;
        }

        .input-wrapper {
            margin-bottom: 24px;
        }

        .login-button-wrapper {
            display: flex;
            justify-content: center;
        }
    }
</style>
